import { createStyles, Paper, Stack, Text } from '@mantine/core';
import { motion } from 'framer-motion';
import { StandardPop } from '../../Utils/MotionConfigs';
import OrderSummaryTable from '../../Components/OrderSummaryTable';
import ReactMarkdown from 'react-markdown';
import { useContext } from 'react';
import { ResqdContext } from '../../Utils/ResqdProvider';

const useStyles = createStyles((theme, _params, getRef) => ({
  root: {
    width: 500,
    maxWidth: '100%',
    border: `solid 2px ${theme.colors.dark[0]}`,
    boxShadow: Array.from(Array(8))
      .map((_, i) => `${i + 1}px ${i + 1}px ${theme.colors.dark[0]}`)
      .join(', '),

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingInline: theme.spacing.md,
    },
  },
}));

const Step6 = () => {
  const { classes } = useStyles();
  const { settings } = useContext(ResqdContext);

  return (
    <motion.div {...StandardPop()}>
      <Paper withBorder p={40} radius="lg" className={classes.root}>
        <Stack align={'stretch'} spacing="lg">
          <Text size={'xl'} align="center">
            <ReactMarkdown
              children={settings.text.step6_title}
              components={{
                p({ node, className, children, ...props }) {
                  return (
                    <Text size={'xl'} align="center">
                      {children}
                    </Text>
                  );
                },
                strong({ node, className, children, ...props }) {
                  return (
                    <Text component="span" weight={'bold'}>
                      {children}
                    </Text>
                  );
                },
              }}
            />
          </Text>
          <OrderSummaryTable />
        </Stack>
      </Paper>
    </motion.div>
  );
};

export default Step6;
