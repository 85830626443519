import React, { useContext, useEffect } from 'react';
import Lottie from 'lottie-react';
import Done from '../../assets/done.json';
import { Center, Stack, Title, Text } from '@mantine/core';
import { motion } from 'framer-motion';
import { EndPop, StandardPop } from '../../Utils/MotionConfigs';
import { useNavigate } from 'react-router';
import { ResqdContext } from '../../Utils/ResqdProvider';
import ReactMarkdown from 'react-markdown';

const Step5 = () => {
  const { settings } = useContext(ResqdContext);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/step6');
    }, 3000);
  }, []);

  return (
    <Stack>
      <motion.div {...StandardPop()} initial={{}} animate={{}}>
        <Center
          sx={() => ({
            width: 320,
            height: 320,
            maxWidth: '100%',
            aspectRatio: '1 / 1',
          })}
        >
          <Lottie animationData={Done} loop={false} autoPlay={true} />
        </Center>
      </motion.div>
      <motion.div {...EndPop(0.8, 0.25)}>
        <Title align="center">{settings.text.step5_title}</Title>
      </motion.div>
      <motion.div {...EndPop(0.95, 0.1)}>
        <ReactMarkdown
          children={settings.text.step5_other}
          components={{
            p({ node, className, children, ...props }) {
              return <Text align="center">{children}</Text>;
            },
            strong({ node, className, children, ...props }) {
              return (
                <Text component="span" weight={'bold'}>
                  {children}
                </Text>
              );
            },
          }}
        />
      </motion.div>
    </Stack>
  );
};

export default Step5;
