import { Container, Tabs } from '@mantine/core';
import React from 'react';
import AdminWrapper from './AdminWrapper';
import Orders from './Orders';
import Settings from './Settings';

const Admin = () => {
  return (
    <AdminWrapper>
      <Container>
        <Tabs mt={40} defaultValue="orders">
          <Tabs.List grow>
            <Tabs.Tab value="orders">Ordrar</Tabs.Tab>
            <Tabs.Tab value="settings">Inställningar</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="orders">
            <Orders />
          </Tabs.Panel>
          <Tabs.Panel value="settings">
            <Settings />
          </Tabs.Panel>
        </Tabs>
      </Container>
    </AdminWrapper>
  );
};

export default Admin;
