import {
  Paper,
  Stack,
  Group,
  Button,
  Text,
  createStyles,
  SimpleGrid,
  Title,
  Slider,
  useMantineTheme,
  ActionIcon,
  NumberInput,
  Image,
} from '@mantine/core';
import { useCounter } from '@mantine/hooks';
import {
  IconArrowLeft,
  IconChevronRight,
  IconMinus,
  IconPlus,
} from '@tabler/icons';
import { motion } from 'framer-motion';
import { useContext, useEffect, useMemo, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Product } from '../../Utils/Classes/Product.class';
import { calculateEmissions, calculateTotal } from '../../Utils/Functions';
import { StandardPop } from '../../Utils/MotionConfigs';
import { ResqdContext } from '../../Utils/ResqdProvider';

const useStyles = createStyles((theme, _params, getRef) => ({
  root: {
    width: 1000,
    maxWidth: '100%',
    border: `solid 2px ${theme.colors.dark[0]}`,
    boxShadow: Array.from(Array(8))
      .map((_, i) => `${i + 1}px ${i + 1}px ${theme.colors.dark[0]}`)
      .join(', '),

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingInline: theme.spacing.md,
    },
  },

  number: {
    borderBottom: 'solid 1px transparent',

    ':focus': {
      outline: 'none',
      borderBottom: 'dashed 1px currentColor',
    },
  },
}));

const Step3 = () => {
  const { classes } = useStyles();
  const { products, formData, settings } = useContext(ResqdContext);

  return (
    <motion.div {...StandardPop()}>
      <Paper withBorder p={40} radius="lg" className={classes.root}>
        <Stack align={'stretch'} spacing="lg">
          <Stack spacing={'xs'}>
            <ReactMarkdown
              children={settings.text.step3_title}
              components={{
                p({ node, className, children, ...props }) {
                  return (
                    <Text size={'xl'} align="center">
                      {children}
                    </Text>
                  );
                },
                strong({ node, className, children, ...props }) {
                  return (
                    <Text component="span" weight={'bold'}>
                      {children}
                    </Text>
                  );
                },
              }}
            />
          </Stack>
          <SimpleGrid
            cols={1}
            breakpoints={[
              { minWidth: 'xs', cols: 1 },
              { minWidth: 'sm', cols: 2 },
              { minWidth: 'md', cols: 3 },
            ]}
            my="xl"
            spacing={'xl'}
          >
            {products.flatMap((product, index) => {
              if (!product.props) return [];
              return (
                <ProductRow product={product} key={product.props.ref.id} />
              );
            })}
          </SimpleGrid>
          <OrderSummary />
          <Group position="apart">
            <Link to={'/step2'}>
              <Button
                size="lg"
                variant="subtle"
                onClick={() => {
                  formData.setArticles({});
                }}
              >
                <IconArrowLeft size={20} />
              </Button>
            </Link>
            <Link to="/step4">
              <Button size="lg" rightIcon={<IconChevronRight size={20} />}>
                {settings.text.step3_button}
              </Button>
            </Link>
          </Group>
        </Stack>
      </Paper>
    </motion.div>
  );
};

export default Step3;

const ProductRow = ({ product }: { product: Product }) => {
  const { theme } = useStyles();

  const { formData } = useContext(ResqdContext);
  const estimate = product.estimate(formData.employees, formData.type);
  const [amount, amountHandlers] = useCounter(
    product.props?.name
      ? formData.articles[product.props.name]?.amount || estimate
      : estimate,
    {
      min: 0,
    }
  );

  useEffect(() => {
    const props = product.props;
    if (!props) return;
    formData.setArticles((p) => {
      let copy = { ...p };
      const cost = amount * props.price;
      const greenhouse_reduction = amount * props.greenhouse_reduction;
      copy[props.name] = {
        amount,
        cost,
        greenhouse_reduction,
      };
      return copy;
    });
  }, [amount]);

  const delay = useRef(0.3 * Math.random());

  const { min, max } = {
    min: 0,
    max: Math.max(8, product.estimate(formData.employees, formData.type) * 2),
  };

  const shadow = useMemo(() => {
    const layers = Math.round(6 * Math.random()) + 3;

    return Array.from(Array(layers))
      .map((_, i) => {
        return `${i + 1}px ${i + 1}px ${theme.colors.dark[0]}`;
      })
      .join(',');
  }, []);

  const interval = useRef<NodeJS.Timer | undefined>(undefined);
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const startHoldChange = (action: 'increment' | 'decrement') => {
    timeout.current = setTimeout(() => {
      interval.current = setInterval(() => {
        amountHandlers[action]();
      }, 50);
    }, 300);
  };

  const endHoldChange = () => {
    clearInterval(interval.current);
    clearTimeout(timeout.current);
  };

  return (
    <motion.div
      {...StandardPop({ enter: delay.current, exit: delay.current }, 0.8)}
    >
      <Paper
        p={"xs"}
        withBorder
        radius={20}
        sx={(theme) => ({
          boxShadow: shadow,
          border: `solid 2px ${theme.colors.dark[0]}`,
          backgroundColor: theme.colors.dark[6]
        })}
      >
        <Stack align="stretch" spacing="xs">
          <Image
            src={product.props?.icon}
            withPlaceholder
            radius={8}
            styles={{
              image: {
                aspectRatio: '5/4',
              },
            }}
          />
          <Stack align={'stretch'} px="sm" py={0} spacing={0}>
            <Text align="center">{product.props?.name}</Text>
            <Group position="apart" noWrap>
              <ActionIcon
                onClick={amountHandlers.decrement}
                onMouseDown={() => startHoldChange('decrement')}
                onMouseUp={endHoldChange}
              >
                <IconMinus size={18} />
              </ActionIcon>
              <NumberInput
                value={amount}
                onChange={(e) => amountHandlers.set(e || 0)}
                min={0}
                variant="unstyled"
                size="xl"
                styles={{
                  root: {
                    width: '100%',
                  },
                  input: {
                    textAlign: 'center',
                    fontFamily: theme.headings.fontFamily,
                    fontWeight: 600,
                    fontSize: theme.headings.sizes.h1.fontSize,
                    height: 'auto',
                  },
                }}
              />
              <ActionIcon
                onClick={amountHandlers.increment}
                onMouseDown={() => startHoldChange('increment')}
                onMouseUp={endHoldChange}
              >
                <IconPlus size={18} />
              </ActionIcon>
            </Group>
            <Slider
              min={min}
              max={max}
              value={amount}
              onChange={(e) => amountHandlers.set(e)}
              label={null}
              mt={8}
              mb={4}
              style={{
                width: '100%',
              }}
            />
          </Stack>
        </Stack>
      </Paper>
    </motion.div>
  );
};

const OrderSummary = () => {
  const {
    formData: { articles },
    settings,
  } = useContext(ResqdContext);

  const theme = useMantineTheme();

  const delay = useRef(0.3 * Math.random());

  const shadowLayers = useRef(Math.round(6 * Math.random()) + 3);

  const shadow = useMemo(() => {
    return Array.from(Array(shadowLayers.current))
      .map((_, i) => {
        return `inset ${i + 1}px ${i + 1}px ${theme.colors.green[5]}`;
      })
      .join(',');
  }, []);

  const totalCost = useMemo(() => {
    return calculateTotal(articles, settings);
  }, [articles, settings]);

  const totalEmissions = useMemo(() => {
    return calculateEmissions(articles, 'kg');
  }, [articles]);

  return (
    <motion.div
      {...StandardPop({ enter: delay.current, exit: delay.current }, 0.8)}
    >
      <Paper
        withBorder
        p={'xs'}
        pt={10 + shadowLayers.current}
        pl={10 + shadowLayers.current}
        radius={20}
        sx={(theme) => ({
          boxShadow: shadow,
          border: `solid 2px ${theme.colors.green[5]}`,
          backgroundColor: theme.colors.dark[6]
        })}
      >
        <SimpleGrid cols={1} breakpoints={[{ minWidth: 'sm', cols: 2 }]}>
          <Stack align={'stretch'} py="lg">
            <Title align="center">
              {Intl.NumberFormat().format(totalCost.total)} kr
            </Title>
            <Text align="center" size={'xl'} style={{ lineHeight: 1 }}>
              {settings.text.step3_price_text.replaceAll(
                '{{discount}}',
                `${Intl.NumberFormat().format(
                  Math.ceil((totalCost.total - settings.freight_cost) * 0.96)
                )} kr`
              )}
              {settings.text.step3_price_description && (
                <Text
                  component="span"
                  align="center"
                  size={'sm'}
                  color="dimmed"
                >
                  <br />
                  {settings.text.step3_price_description}
                </Text>
              )}
            </Text>
          </Stack>
          <Stack
            align={'stretch'}
            py="lg"
            sx={(theme) => ({
              backgroundColor: theme.colors.green[5],
              color: theme.colors.dark[9],
              borderRadius: 16,
            })}
          >
            <Title align="center">
              {Intl.NumberFormat().format(totalEmissions)} kg
            </Title>
            <Text align="center" size={'xl'} style={{ lineHeight: 1 }}>
              {settings.text.step3_greenhouse_text}
              {settings.text.step3_greenhouse_description && (
                <Text
                  component="span"
                  align="center"
                  size={'sm'}
                  style={{ opacity: 0.6 }}
                >
                  <br />
                  {settings.text.step3_greenhouse_description}
                </Text>
              )}
            </Text>
          </Stack>
        </SimpleGrid>
      </Paper>
    </motion.div>
  );
};
