import {
  Anchor,
  Button,
  Card,
  createStyles,
  Group,
  NumberInput,
  Stack,
  Table,
  Text,
  TextInput,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeModal, openModal } from '@mantine/modals';
import {
  IconGripVertical,
  IconPhoto,
  IconShoppingCart,
  IconUpload,
  IconX,
} from '@tabler/icons';
import { useContext, useEffect, useState } from 'react';
import { IconDonut, IconToaster } from '../../../Components/Icons';
import { Product } from '../../../Utils/Classes/Product.class';
import { v4 as uuid } from 'uuid';
import { ResqdContext } from '../../../Utils/ResqdProvider';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useCounter, useListState } from '@mantine/hooks';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { showNotification } from '@mantine/notifications';

const useStyles = createStyles((theme, _params, getRef) => ({
  dragHandle: {
    ...theme.fn.focusStyles(),
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
  },
}));

const Products = () => {
  const { classes } = useStyles();

  const { products } = useContext(ResqdContext);

  const [productList, productListHandlers] = useListState(products);

  useEffect(() => {
    productListHandlers.setState(products);
  }, [products]);

  const [orderKey, orderKeyHandlers] = useCounter(0);
  useEffect(() => {
    if (!orderKey) return;
    productList.forEach((product, index) => {
      product.update({ order: index + 1 });
    });
  }, [orderKey]);

  return (
    <Card withBorder shadow={'sm'} radius="md">
      <Card.Section withBorder inheritPadding py={'xs'}>
        <Group spacing={'xs'}>
          <IconShoppingCart size={20} />
          <Text weight={500}>Produkter</Text>
        </Group>
      </Card.Section>
      <Card.Section>
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            productListHandlers.reorder({
              from: source.index,
              to: destination?.index || 0,
            });
            orderKeyHandlers.increment();
          }}
        >
          <Table horizontalSpacing={'md'} highlightOnHover>
            <thead>
              <tr>
                <th style={{ width: 256 }}>Namn</th>
                <th style={{ width: 128 }}>Pris</th>
                <th style={{ width: 160 }}>Antal frukost</th>
                <th style={{ width: 160 }}>Antal fika</th>
                <th></th>
              </tr>
            </thead>

            <Droppable droppableId="dnd-list" direction="vertical">
              {(provided) => {
                let index = -1;
                return (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {productList.flatMap((product) => {
                      const props = product.props;
                      if (!props) return [];
                      index++;
                      return (
                        <Draggable
                          key={props.ref.id}
                          index={index}
                          draggableId={props.ref.id}
                        >
                          {(provided) => (
                            <UnstyledButton
                              component="tr"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              onClick={() => {
                                const id = uuid();
                                openModal({
                                  title: 'Ändra produkt',
                                  children: (
                                    <CDProductModal
                                      onClose={() => closeModal(id)}
                                      product={product}
                                    />
                                  ),
                                  modalId: id,
                                });
                              }}
                            >
                              <td style={{ width: 256 }}>{props.name}</td>
                              <td style={{ width: 128 }}>{props.price}</td>
                              <td style={{ width: 160 }}>
                                {props.per_person_frukost}
                              </td>
                              <td style={{ width: 160 }}>
                                {props.per_person_fika}
                              </td>
                              <td>
                                <div
                                  className={classes.dragHandle}
                                  {...provided.dragHandleProps}
                                >
                                  <IconGripVertical size={18} stroke={1.5} />
                                </div>
                              </td>
                            </UnstyledButton>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </tbody>
                );
              }}
            </Droppable>

            <tfoot>
              <tr>
                <th>
                  <Anchor
                    onClick={() => {
                      const id = uuid();
                      openModal({
                        title: 'Skapa produkt',
                        children: (
                          <CDProductModal onClose={() => closeModal(id)} />
                        ),
                        modalId: id,
                      });
                    }}
                  >
                    + Lägg till produkt
                  </Anchor>
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </Table>
        </DragDropContext>
      </Card.Section>
    </Card>
  );
};

export default Products;

const CDProductModal = ({
  product,
  onClose,
}: {
  product?: Product;
  onClose: () => void;
}) => {
  const theme = useMantineTheme();
  const { products } = useContext(ResqdContext);

  const form = useForm({
    initialValues: {
      name: product?.props?.name || '',
      price: product?.props?.price || 0,
      per_person_frukost: product?.props?.per_person_frukost || 0,
      per_person_fika: product?.props?.per_person_fika || 0,
      greenhouse_reduction: product?.props?.greenhouse_reduction || 0,
      icon: product?.props?.icon || '',
    },

    validate: {
      name: (value) => (value ? null : 'Ange ett namn'),
      price: (value) => (value ? null : 'Ange ett pris'),
      per_person_frukost: (value) =>
        value === undefined || value === null ? 'Ange ett antal' : null,
      per_person_fika: (value) =>
        value === undefined || value === null ? 'Ange ett antal' : null,
    },
  });

  const [loading, setLoading] = useState(false);
  const submit = () => {
    if (loading) return;
    setLoading(true);
    const {
      name,
      price,
      per_person_frukost,
      per_person_fika,
      greenhouse_reduction,
      icon,
    } = form.values;

    if (product) {
      product
        .update({
          name,
          price,
          per_person_frukost,
          per_person_fika,
          greenhouse_reduction,
        })
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          form.setFieldError(
            'name',
            'Det gick inte att uppdatera produkten. Vänligen försök igen senare.'
          );
        });
    } else {
      new Product()
        .create({
          name,
          icon,
          price,
          per_person_frukost,
          per_person_fika,
          greenhouse_reduction,
          order: products.length + 1,
        })
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          form.setFieldError(
            'name',
            'Det gick inte att skapa produkten. Vänligen försök igen senare.'
          );
        });
    }
  };

  return (
    <>
      <Stack>
        <Dropzone
          onDrop={(files) => {
            const file = files[0];
            if (!file) return;

            const storage = getStorage();
            const storageRef = ref(
              storage,
              `product_icons/${uuid()}.${file.name.split('.').pop()}`
            );

            uploadBytes(storageRef, file)
              .then((snapshot) => {
                return getDownloadURL(snapshot.ref);
              })
              .then((res) => {
                form.setFieldValue('icon', res);
                if (product) {
                  return product.update({ icon: res });
                }
              })
              .catch((err) => {
                console.error(err);
                showNotification({
                  title: 'Något gick snett',
                  message:
                    'Någonting gick inte riktigt som det skulle när ikonen laddades upp. Var vänlig försök igen senare eller med en annan fil.',
                  autoClose: 5000,
                  color: 'red',
                });
              });
          }}
          onReject={(files) => {
            console.log('rejected files ', files);
          }}
          accept={IMAGE_MIME_TYPE}
          maxSize={2 * 1024 ** 2}
          maxFiles={1}
        >
          <Stack
            align={'center'}
            justify={'center'}
            spacing="xl"
            style={{ minHeight: 220, pointerEvents: 'none' }}
          >
            <Dropzone.Accept>
              <IconUpload
                size={50}
                stroke={1.5}
                color={
                  theme.colors[theme.primaryColor][
                    theme.colorScheme === 'dark' ? 4 : 6
                  ]
                }
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX
                size={50}
                stroke={1.5}
                color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
              />
            </Dropzone.Reject>
            <Dropzone.Idle>
              {form.values.icon ? (
                <img
                  src={form.values.icon}
                  style={{
                    width: 96,
                    height: 96,
                    borderRadius: theme.radius.sm,
                    objectFit: 'cover',
                  }}
                  alt=""
                />
              ) : (
                <IconPhoto size={50} stroke={1.5} />
              )}
            </Dropzone.Idle>

            <div>
              <Text size="xl" inline align="center">
                Produkens ikon
              </Text>
              <Text size="sm" color="dimmed" inline mt={7} align="center">
                Dra hit bilden eller klicka för att välja
              </Text>
            </div>
          </Stack>
        </Dropzone>
        <TextInput
          label="Namn"
          placeholder="Produktens namn"
          required
          {...form.getInputProps('name')}
        />
        <NumberInput
          label="Pris / st"
          required
          decimalSeparator=","
          precision={3}
          step={0.05}
          min={0}
          {...form.getInputProps('price')}
        />
        <NumberInput
          icon={<IconToaster side={16} />}
          label="Antal per person (frukost)"
          required
          decimalSeparator=","
          precision={3}
          step={0.05}
          min={0}
          {...form.getInputProps('per_person_frukost')}
        />
        <NumberInput
          icon={<IconDonut side={16} />}
          label="Antal per person (fika)"
          required
          decimalSeparator=","
          precision={3}
          step={0.05}
          min={0}
          {...form.getInputProps('per_person_fika')}
        />
        <NumberInput
          icon={<IconDonut side={16} />}
          label="Minskning CO2 i gram per styck"
          required
          decimalSeparator=","
          precision={3}
          step={0.1}
          min={0}
          {...form.getInputProps('greenhouse_reduction')}
        />
        <Button
          mt={'lg'}
          loading={loading}
          onClick={() => {
            if (form.validate().hasErrors) {
              return;
            }
            submit();
          }}
        >
          {product ? 'Spara' : 'Skapa produkt'}
        </Button>
      </Stack>
    </>
  );
};
