import { Center, createStyles } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import { useViewportSize } from '@mantine/hooks';

const useStyles = createStyles((theme, _params, getRef) => ({
  root: {
    minHeight: '100vh',

    '> *': {
      maxWidth: '100%',
    },
  },
}));

const MESSAGE_URLS = [
  'http://resqd2.local/',
  'https://resqd.se/',
  'https://www.resqd.se/',
  'https://resqd.digitalmadman.se/',
];

const Booking = () => {
  const { classes } = useStyles();

  const [alignedCenter, setAlignedCenter] = useState(true);
  const viewport = useViewportSize();
  const [debouncedViewport, setDebouncedViewport] = useState<{
    width: number;
    height: number;
    hasTransition: boolean;
  }>({ ...viewport, hasTransition: false });
  useEffect(() => {
    if (viewport.width < debouncedViewport.width) {
      setDebouncedViewport({ ...viewport, hasTransition: false });
    } else {
      const timer = setTimeout(() => {
        setDebouncedViewport({ ...viewport, hasTransition: true });
      }, 150);
      return clearTimeout(timer);
    }
  }, [viewport]);

  const location = useLocation();

  useEffect(() => {
    let step = 0;
    switch (location.pathname) {
      case '/':
        step = 1;
        break;
      case '/step2':
        step = 2;
        break;
      case '/step3':
        step = 3;
        break;
      case '/step4':
        step = 4;
        break;
      case '/step5':
        step = 5;
        break;
      case '/step6':
        step = 6;
        break;
      default:
        break;
    }

    MESSAGE_URLS.forEach((url) => {
      try {
        window.parent.postMessage(`form-step-${step}`, url);
      } catch (error) {
        console.error(error);
      }
    });
  }, [location]);

  useEffect(() => {
    MESSAGE_URLS.forEach((url) => {
      try {
        window.parent.postMessage('LOADED', url);
      } catch (error) {
        console.error(error);
      }
    });

    const handler = (event: MessageEvent<any>) => {
      switch (event.data) {
        case 'CENTER':
          setAlignedCenter(true);
          break;
        case 'TOP':
          setAlignedCenter(false);
          break;
        default:
          break;
      }
    };
    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      MESSAGE_URLS.forEach((url) => {
        try {
          window.parent.postMessage(
            `form-height-${document.body.scrollHeight}`,
            url
          );
        } catch (error) {
          console.error(error);
        }
      });
    });

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    document.body.classList.add('transparent');

    return () => {
      document.body.classList.remove('transparent');
    };
  }, []);

  return (
    <Center
      className={classes.root}
      sx={(theme) => ({
        alignItems: alignedCenter ? undefined : 'flex-start',
        paddingBlock: alignedCenter ? undefined : theme.spacing.xl,
        width:
          debouncedViewport.width < window.innerWidth
            ? window.innerWidth
            : debouncedViewport.width,
        transition: debouncedViewport.hasTransition
          ? 'width 0.3s cubic-bezier(0.65, 0, 0.35, 1)'
          : '',
      })}
      p="md"
    >
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Step1 />} />
          <Route path="step2" element={<Step2 />} />
          <Route path="step3" element={<Step3 />} />
          <Route path="step4" element={<Step4 />} />
          <Route path="step5" element={<Step5 />} />
          <Route path="step6" element={<Step6 />} />
        </Routes>
      </AnimatePresence>
    </Center>
  );
};

export default Booking;
