import {
  DocumentSnapshot,
  DocumentReference,
  updateDoc,
  addDoc,
  collection,
  getFirestore,
  getDoc,
} from 'firebase/firestore';

export interface ProductParams {
  name: string;
  icon: string;
  price: number;
  per_person_frukost: number;
  per_person_fika: number;
  greenhouse_reduction: number;
  ref: DocumentReference;
  order: number;
}

export class Product {
  props?: ProductParams;

  init({ ...args }: ProductParams) {
    this.props = args;
  }

  get(ref: DocumentReference): Promise<Product> {
    return getDoc(ref).then((snapshot) => {
      return this.decode(snapshot);
    });
  }

  decode(snapshot: DocumentSnapshot): Product {
    let args = snapshot.data() as ProductParams;
    args.ref = snapshot.ref;

    this.init(args);
    return this;
  }

  update({
    ...args
  }: Partial<
    Pick<
      ProductParams,
      | 'name'
      | 'icon'
      | 'per_person_fika'
      | 'per_person_frukost'
      | 'price'
      | 'order'
      | 'greenhouse_reduction'
    >
  >): Promise<boolean | void> {
    return new Promise((resolve, reject) => {
      if (!this.props?.ref) {
        resolve(false);
        return;
      }

      const params = removeEmpty(args);
      return updateDoc(this.props.ref, params).then((result) => {
        resolve(result);
      });
    });
  }

  create({ ...args }: Omit<ProductParams, 'ref'>): Promise<Product> {
    return addDoc(
      collection(getFirestore(), 'platform', 'settings', 'products'),
      args
    ).then((result) => {
      return this.get(result).then((result) => {
        return result;
      });
    });
  }

  estimate(employees: number, type: 'frukost' | 'fika'): number {
    let perPerson = 0;
    switch (type) {
      case 'frukost':
        perPerson = this.props?.per_person_frukost || 0;
        break;
      case 'fika':
        perPerson = this.props?.per_person_fika || 0;
        break;
      default:
        break;
    }
    return Math.round(employees * perPerson);
  }
}

function removeEmpty(obj: object): object {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );
}
