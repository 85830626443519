import { Target, TargetAndTransition } from 'framer-motion';

interface Props {
  initial?: Target;
  animate?: TargetAndTransition;
  exit?: TargetAndTransition;
}

export const StandardPop = (
  delay: {
    enter: number,
    exit: number
  } = {
    enter: 0,
    exit: 0,
  },
  scale: number = 0.95
): Props => ({
  initial: {
    opacity: 0,
    scale: scale,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: 'easeOut',
      delay: delay.enter,
    },
  },
  exit: {
    opacity: 0,
    scale: scale,
    transition: {
      ease: 'easeIn',
      delay: delay.exit,
    },
  },
});

export const EndPop = (enterDelay: number = 0, exitDelay: number = 0): Props => ({
  initial: {
    opacity: 0,
    y: 24,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeOut',
      delay: enterDelay,
    },
  },
  exit: {
    opacity: 0,
    y: 24,
    transition: {
      ease: 'easeIn',
      delay: exitDelay,
    },
  },
});
