import {
  DocumentSnapshot,
  DocumentReference,
  addDoc,
  collection,
  getFirestore,
  getDoc,
  FieldValue,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { ResqdProps } from '../ResqdProvider';

export interface OrderParams {
  name: string;
  email: string;
  phone: string;
  registration: string;
  delivery_date: Date | Timestamp;
  other: string;
  products: ResqdProps['formData']['articles'];
  cost: number;
  vat: number;
  ref: DocumentReference;
  status: 'new' | 'processed' | 'delivered';
  time_created: Date | FieldValue;
}

export class Order {
  props?: OrderParams;

  init({ ...args }: OrderParams) {
    this.props = args;
  }

  get(ref: DocumentReference): Promise<Order> {
    return getDoc(ref).then((snapshot) => {
      return this.decode(snapshot);
    });
  }

  decode(snapshot: DocumentSnapshot): Order {
    let args = snapshot.data() as OrderParams;
    args.ref = snapshot.ref;

    this.init(args);
    return this;
  }

  create({
    ...args
  }: Omit<OrderParams, 'time_created' | 'ref' | 'status'>): Promise<Order> {
    const params: Omit<OrderParams, 'ref'> = {
      ...args,
      time_created: serverTimestamp(),
      status: 'new',
    };
    return addDoc(collection(getFirestore(), 'orders'), params).then(
      (result) => {
        return this.get(result).then((result) => {
          return result;
        });
      }
    );
  }

  updateStatus(new_status: OrderParams['status']): Promise<Order> {
    return new Promise((resolve, reject) => {
      if (!this.props?.ref) {
        resolve(this);
        return;
      }
      const params: Pick<OrderParams, 'status'> = {
        status: new_status,
      };
      return updateDoc(this.props.ref, params).then((result) => {
        if (!this.props?.ref) {
          resolve(this);
          return;
        }
        return this.get(this.props.ref).then((res) => resolve(res));
      });
    });
  }
}
