import {
  Paper,
  Title,
  Text,
  Slider,
  createStyles,
  Stack,
  Group,
  Button,
  NumberInput,
  Skeleton,
} from '@mantine/core';
import { IconChevronRight } from '@tabler/icons';
import { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { StandardPop } from '../../Utils/MotionConfigs';
import { ResqdContext } from '../../Utils/ResqdProvider';
import ReactMarkdown from 'react-markdown';

const useStyles = createStyles((theme, _params, getRef) => ({
  root: {
    width: 500,
    maxWidth: '100%',
    border: `solid 2px ${theme.colors.dark[0]}`,
    boxShadow: Array.from(Array(8))
      .map((_, i) => `${i + 1}px ${i + 1}px ${theme.colors.dark[0]}`)
      .join(', '),
    padding: 40,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingInline: theme.spacing.md,
    },
  },
  number: {
    fontSize: 96,
    borderBottom: 'solid 1px transparent',

    ':focus': {
      outline: 'none',
      borderBottom: 'dashed 1px currentColor',
    },
  },
  slider: {
    width: '100%',
  },
}));

const Step1 = () => {
  const { classes, theme } = useStyles();
  const {
    formData: { employees, setEmployees },
    settings,
  } = useContext(ResqdContext);
  const employeeLimits = [1, 100];

  return (
    <motion.div {...StandardPop()}>
      <Paper withBorder radius="lg" className={classes.root}>
        <Stack align={'stretch'} spacing="lg">
          {settings.text.step1_title ? (
            <ReactMarkdown
              children={settings.text.step1_title}
              components={{
                p({ node, className, children, ...props }) {
                  return (
                    <Text size={'xl'} align="center">
                      {children}
                    </Text>
                  );
                },
                strong({ node, className, children, ...props }) {
                  return (
                    <Text component="span" weight={'bold'}>
                      {children}
                    </Text>
                  );
                },
              }}
            />
          ) : (
            <Stack spacing={4}>
              <Skeleton
                width={400}
                style={{
                  maxWidth: '100%',
                }}
                height={30}
              />
            </Stack>
          )}
          <NumberInput
            value={employees}
            onChange={(e) => setEmployees(e || employeeLimits[0])}
            min={employeeLimits[0]}
            variant="unstyled"
            size="xl"
            styles={{
              root: {
                width: '100%',
              },
              input: {
                textAlign: 'center',
                fontFamily: theme.headings.fontFamily,
                fontWeight: 600,
                fontSize: 96,
                lineHeight: 1.6,
                height: "auto",
                overflow: 'visible'
              },
            }}
          />
          <Slider
            value={employees}
            onChange={(e) => setEmployees(e)}
            label={null}
            min={employeeLimits[0]}
            max={employeeLimits[1]}
            className={classes.slider}
          />
          <Group position="right">
            {settings.text.step1_button ? (
              <Link to="/step2">
                <Button size="lg" rightIcon={<IconChevronRight size={20} />}>
                  {settings.text.step1_button}
                </Button>
              </Link>
            ) : (
              <Skeleton
                width={150}
                style={{
                  maxWidth: '100%',
                }}
                height={50}
              />
            )}
          </Group>
        </Stack>
      </Paper>
    </motion.div>
  );
};

export default Step1;
