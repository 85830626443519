import { Timestamp } from 'firebase/firestore';
import {
  Card,
  Group,
  Table,
  UnstyledButton,
  Text,
  ActionIcon,
  Tooltip,
  ThemeIcon,
} from '@mantine/core';
import { IconTruck, IconTruckDelivery, IconTruckLoading } from '@tabler/icons';
import moment from 'moment';
import { useMemo } from 'react';
import { Order } from '../../../Utils/Classes/Order.class';
import { useSearchParams } from 'react-router-dom';

const NewOrders = ({ orders }: { orders: Order[] }) => {
  return (
    <Card withBorder shadow={'sm'} radius="md">
      <Card.Section withBorder inheritPadding py={'xs'}>
        <Group spacing={'xs'}>
          <ThemeIcon variant="light" color={'cyan'}>
            <IconTruck size={20} />
          </ThemeIcon>
          <Text weight={500}>Nya beställningar</Text>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table horizontalSpacing={'md'} highlightOnHover>
          <thead>
            <tr>
              <th>Namn</th>
              <th>Beställd</th>
              <th>Önskad leverans</th>
              <th>Ordervärde</th>
              <th>Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              return <OrderRow order={order} key={order.props?.ref.id} />;
            })}
          </tbody>
        </Table>
      </Card.Section>
    </Card>
  );
};

export default NewOrders;

export const OrderRow = ({ order }: { order: Order }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const date = useMemo(() => {
    if (order.props?.time_created instanceof Timestamp) {
      return moment(order.props.time_created.toDate()).fromNow();
    }
    return '';
  }, [order.props?.time_created]);

  const delivery_date = useMemo(() => {
    if (order.props?.delivery_date instanceof Timestamp) {
      return moment(order.props.delivery_date.toDate()).format('Do MMM YYYY');
    }
  }, [order.props?.delivery_date]);

  return (
    <UnstyledButton
      component="tr"
      onClick={() => {
        searchParams.append('order', order.props?.ref.id || "");
        setSearchParams(searchParams)
      }}
    >
      <td>{order.props?.name}</td>
      <td>{date}</td>
      <td>{delivery_date}</td>
      <td>{Intl.NumberFormat().format(order.props?.cost || 0)} kr</td>
      <td>
        <Group spacing={'xs'}>
          <Tooltip label="Markera som ny">
            <ActionIcon
              size={'sm'}
              variant="light"
              onClick={(e) => {
                e.stopPropagation();
                order.updateStatus('new');
              }}
              disabled={order.props?.status === 'new'}
              color="cyan"
            >
              <IconTruck size={16} />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Markera som hanterad">
            <ActionIcon
              size={'sm'}
              variant="light"
              onClick={(e) => {
                e.stopPropagation();
                order.updateStatus('processed');
              }}
              disabled={order.props?.status === 'processed'}
              color="yellow"
            >
              <IconTruckDelivery size={16} />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Markera som levererad">
            <ActionIcon
              size={'sm'}
              variant="light"
              onClick={(e) => {
                e.stopPropagation();
                order.updateStatus('delivered');
              }}
              disabled={order.props?.status === 'delivered'}
              color="green"
            >
              <IconTruckLoading size={16} />
            </ActionIcon>
          </Tooltip>
        </Group>
      </td>
    </UnstyledButton>
  );
};
