import {
  Card,
  Group,
  Table,
  UnstyledButton,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { openModal } from '@mantine/modals';
import { IconTruckLoading } from '@tabler/icons';
import { v4 as uuid } from 'uuid';
import { Order } from '../../../Utils/Classes/Order.class';
import { OrderRow } from './NewOrders';

const DeliveredOrders = ({ orders }: { orders: Order[] }) => {
  return (
    <Card withBorder shadow={'sm'} radius="md">
      <Card.Section withBorder inheritPadding py={'xs'}>
        <Group spacing={'xs'}>
          <ThemeIcon variant="light" color={"green"}>
            <IconTruckLoading size={20} />
          </ThemeIcon>
          <Text weight={500}>Levererade beställningar</Text>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table horizontalSpacing={'md'} highlightOnHover>
          <thead>
            <tr>
              <th>Namn</th>
              <th>Beställd</th>
              <th>Önskad leverans</th>
              <th>Ordervärde</th>
              <th>Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              return <OrderRow order={order} key={order.props?.ref.id} />;
            })}
          </tbody>
        </Table>
      </Card.Section>
    </Card>
  );
};

export default DeliveredOrders;
