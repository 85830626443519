import { User, onAuthStateChanged, getAuth } from 'firebase/auth';
import {
  onSnapshot,
  collection,
  getFirestore,
  query,
  orderBy,
  doc,
} from 'firebase/firestore';
import moment from 'moment';
import React, { createContext, useEffect, useState } from 'react';
import { Order } from './Classes/Order.class';
import { Product } from './Classes/Product.class';

const ResqdProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<ResqdProps['user']>(getAuth().currentUser);

  useEffect(() => {
    const listener = onAuthStateChanged(getAuth(), (credential) => {
      setUser(credential);
    });
    return () => listener();
  }, []);

  const [orders, setOrders] = useState<Order[]>([]);
  useEffect(() => {
    if (!user) return;
    const listener = onSnapshot(
      query(collection(getFirestore(), 'orders'), orderBy('time_created', 'desc')),
      (snapshot) => {
        setOrders(
          snapshot.docs.map((snapshot) => new Order().decode(snapshot))
        );
      }
    );

    return () => listener();
  }, [user]);

  const [products, setProducts] = useState<ResqdProps['products']>(
    initialResqd.products
  );
  useEffect(() => {
    const listener = onSnapshot(
      query(
        collection(getFirestore(), 'platform', 'settings', 'products'),
        orderBy('order', 'asc')
      ),
      (snapshot) => {
        setProducts(
          snapshot.docs.map((snapshot) => {
            return new Product().decode(snapshot);
          })
        );
      }
    );

    return () => listener();
  }, []);

  const [employees, setEmployees] = useState<
    ResqdProps['formData']['employees']
  >(initialResqd.formData.employees);
  const [type, setType] = useState<ResqdProps['formData']['type']>(
    initialResqd.formData.type
  );

  const [articles, setArticles] = useState<ResqdProps['formData']['articles']>(
    initialResqd.formData.articles
  );

  const [identity, setIdentity] = useState<ResqdProps['formData']['identity']>(
    initialResqd.formData.identity
  );

  const [order, setOrder] = useState<ResqdProps['order']>();

  const [settings, setSettings] = useState<ResqdProps['settings']>(
    initialResqd.settings
  );

  useEffect(() => {
    const listener = onSnapshot(
      doc(getFirestore(), 'platform', 'settings'),
      (snapshot) => {
        if (snapshot.exists()) {
          setSettings({ ...snapshot.data() } as ResqdProps['settings']);
        }
      }
    );

    return () => listener();
  }, []);

  return (
    <ResqdContext.Provider
      value={{
        user: user,
        products: products,
        orders: orders,
        formData: {
          employees,
          setEmployees,
          type,
          setType,
          articles,
          setArticles,
          identity,
          setIdentity,
        },
        order,
        setOrder,
        settings,
      }}
    >
      {children}
    </ResqdContext.Provider>
  );
};

export default ResqdProvider;

export interface ResqdProps {
  user: null | User;
  products: Product[];
  orders: Order[];
  formData: {
    employees: number;
    setEmployees: React.Dispatch<
      React.SetStateAction<ResqdProps['formData']['employees']>
    >;
    type: 'frukost' | 'fika';
    setType: React.Dispatch<
      React.SetStateAction<ResqdProps['formData']['type']>
    >;
    articles: Record<
      string,
      {
        amount: number;
        cost: number;
        greenhouse_reduction: number;
      }
    >;
    setArticles: React.Dispatch<
      React.SetStateAction<ResqdProps['formData']['articles']>
    >;
    identity: {
      name: string;
      email: string;
      phone: string;
      registration: string;
      delivery: Date;
      other: string;
    };
    setIdentity: React.Dispatch<
      React.SetStateAction<ResqdProps['formData']['identity']>
    >;
  };
  order: Order | undefined;
  setOrder: React.Dispatch<React.SetStateAction<ResqdProps['order']>>;
  settings: {
    freight_cost: number;
    text: {
      step1_title: string;
      step1_button: string;
      step2_title: string;
      step2_button: string;
      step3_title: string;
      step3_price_text: string;
      step3_price_description: string;
      step3_greenhouse_text: string;
      step3_greenhouse_description: string;
      step3_button: string;
      step4_title: string;
      step4_button: string;
      step5_title: string;
      step5_other: string;
      step6_title: string;
    };
  };
}

export const initialResqd: ResqdProps = {
  user: null,
  products: [],
  orders: [],
  formData: {
    employees: 1,
    setEmployees: () => {},
    type: 'frukost',
    setType: () => {},
    articles: {},
    setArticles: () => {},
    identity: {
      name: '',
      email: '',
      phone: '',
      registration: '',
      delivery: moment().add(2, 'days').toDate(),
      other: '',
    },
    setIdentity: () => {},
  },
  order: undefined,
  setOrder: () => {},
  settings: {
    freight_cost: 0,
    text: {
      step1_title: '',
      step1_button: '',
      step2_title: '',
      step2_button: '',
      step3_title: '',
      step3_price_text: '',
      step3_price_description: '',
      step3_greenhouse_text: '',
      step3_greenhouse_description: '',
      step3_button: '',
      step4_title: '',
      step4_button: '',
      step5_title: '',
      step5_other: '',
      step6_title: '',
    }
  },
};

export const ResqdContext = createContext<ResqdProps>({
  ...initialResqd,
});
