import { ActionIcon, Group, Stack, Tooltip, Modal } from '@mantine/core';
import { IconTruck, IconTruckDelivery, IconTruckLoading } from '@tabler/icons';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import OrderSummaryTable from '../../Components/OrderSummaryTable';
import { Order } from '../../Utils/Classes/Order.class';
import { ResqdContext } from '../../Utils/ResqdProvider';
import DeliveredOrders from './Orders/DeliveredOrders';
import NewOrders from './Orders/NewOrders';
import ProcessedOrders from './Orders/ProcessedOrders';

const Orders = () => {
  const { orders } = useContext(ResqdContext);

  const newOrders = useMemo(() => {
    return orders.filter((e) => e.props?.status === 'new');
  }, [orders]);

  const processedOrders = useMemo(() => {
    return orders.filter((e) => e.props?.status === 'processed');
  }, [orders]);

  const deliveredOrders = useMemo(() => {
    return orders.filter((e) => e.props?.status === 'delivered');
  }, [orders]);

  return (
    <Stack spacing={'xl'} my={'xl'} align="stretch">
      <NewOrders orders={newOrders} />
      <ProcessedOrders orders={processedOrders} />
      <DeliveredOrders orders={deliveredOrders} />
      <OrderDetailsModal />
    </Stack>
  );
};

export default Orders;

const OrderDetailsModal = () => {
  const { orders } = useContext(ResqdContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    if (searchParams.has('order')) {
      const tempOrder = orders.filter(
        (e) => e.props?.ref.id === searchParams.get('order')
      );
      if (tempOrder[0]) {
        setOrder(tempOrder[0]);
        return;
      }
    }
    setOrder(undefined)
  }, [searchParams, orders]);

  return (
    <Modal
      opened={!!order}
      onClose={() => {
        searchParams.delete('order');
        setSearchParams(searchParams);
      }}
    >
      {}
      <OrderSummaryTable providedOrder={order} />
      <Group position="right">
        <Tooltip label="Markera som ny">
          <ActionIcon
            size={'md'}
            variant="light"
            onClick={(e) => {
              if (!order) return;
              e.stopPropagation();
              order.updateStatus('new');
            }}
            disabled={order?.props?.status === 'new'}
            color="cyan"
          >
            <IconTruck size={16} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Markera som hanterad">
          <ActionIcon
            size={'md'}
            variant="light"
            onClick={(e) => {
              if (!order) return;
              e.stopPropagation();
              order.updateStatus('processed');
            }}
            disabled={order?.props?.status === 'processed'}
            color="yellow"
          >
            <IconTruckDelivery size={16} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Markera som levererad">
          <ActionIcon
            size={'md'}
            variant="light"
            onClick={(e) => {
              if (!order) return;
              e.stopPropagation();
              order.updateStatus('delivered');
            }}
            disabled={order?.props?.status === 'delivered'}
            color="green"
          >
            <IconTruckLoading size={16} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Modal>
  );
};
