import {
  Paper,
  Stack,
  Group,
  Button,
  Text,
  SegmentedControl,
  createStyles,
} from '@mantine/core';
import { IconArrowLeft, IconChevronRight } from '@tabler/icons';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { IconDonut, IconToaster } from '../../Components/Icons';
import { StandardPop } from '../../Utils/MotionConfigs';
import { ResqdContext, ResqdProps } from '../../Utils/ResqdProvider';

const useStyles = createStyles((theme, _params, getRef) => ({
  root: {
    width: 500,
    maxWidth: '100%',
    border: `solid 2px ${theme.colors.dark[0]}`,
    boxShadow: Array.from(Array(8))
      .map((_, i) => `${i + 1}px ${i + 1}px ${theme.colors.dark[0]}`)
      .join(', '),
    padding: 40,

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingInline: theme.spacing.md,
    },
  },
}));

const Step2 = () => {
  const { classes, theme } = useStyles();
  const {
    formData: { type, setType },
    settings
  } = useContext(ResqdContext);

  return (
    <motion.div {...StandardPop()}>
      <Paper withBorder radius="lg" className={classes.root}>
        <Stack align={'stretch'} spacing="lg">
          <Stack spacing={'xs'}>
            <ReactMarkdown
              children={settings.text.step2_title}
              components={{
                p({ node, className, children, ...props }) {
                  return (
                    <Text size={'xl'} align="center">
                      {children}
                    </Text>
                  );
                },
                strong({ node, className, children, ...props }) {
                  return (
                    <Text component="span" weight={'bold'}>
                      {children}
                    </Text>
                  );
                },
              }}
            />
          </Stack>
          <SegmentedControl
            data={[
              {
                label: (
                  <Stack align={'center'} spacing={'xs'} p={'sm'}>
                    <IconToaster side={32} />
                    Frukost
                  </Stack>
                ),
                value: 'frukost',
              },
              {
                label: (
                  <Stack align={'center'} spacing={'xs'} p={'sm'}>
                    <IconDonut side={32} />
                    Fika
                  </Stack>
                ),
                value: 'fika',
              },
            ]}
            value={type}
            onChange={(e: ResqdProps['formData']['type']) => {
              setType(e);
            }}
            size="xl"
            radius={'lg'}
            fullWidth
            styles={{
              label: {
                fontFamily: "obviously, 'Averia Sans Libre', sans-serif",
              },

              root: {
                overflow: 'visible',
                backgroundColor: 'transparent',
              },

              controlActive: {
                border: `solid 2px ${theme.colors.dark[0]}`,
                boxShadow: Array.from(Array(8))
                  .map(
                    (_, i) => `${i + 1}px ${i + 1}px ${theme.colors.dark[0]}`
                  )
                  .join(', '),
              },
            }}
          />
          <Group position="apart">
            <Link to={'/'}>
              <Button size="lg" variant="subtle">
                <IconArrowLeft size={20} />
              </Button>
            </Link>
            <Link to="/step3">
              <Button size="lg" rightIcon={<IconChevronRight size={20} />}>
                {settings.text.step2_button}
              </Button>
            </Link>
          </Group>
        </Stack>
      </Paper>
    </motion.div>
  );
};

export default Step2;
