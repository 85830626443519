import { Card, Group, Table, Text, ThemeIcon } from '@mantine/core';
import { IconTruckDelivery } from '@tabler/icons';
import { Order } from '../../../Utils/Classes/Order.class';
import { OrderRow } from './NewOrders';

const ProcessedOrders = ({ orders }: { orders: Order[] }) => {
  return (
    <Card withBorder shadow={'sm'} radius="md">
      <Card.Section withBorder inheritPadding py={'xs'}>
        <Group spacing={'xs'}>
          <ThemeIcon variant="light" color={"yellow"}>
            <IconTruckDelivery size={20} />
          </ThemeIcon>
          <Text weight={500}>Hanterade beställningar</Text>
        </Group>
      </Card.Section>
      <Card.Section>
        <Table horizontalSpacing={'md'} highlightOnHover>
          <thead>
            <tr>
              <th>Namn</th>
              <th>Beställd</th>
              <th>Önskad leverans</th>
              <th>Ordervärde</th>
              <th>Åtgärder</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              return <OrderRow order={order} key={order.props?.ref.id} />;
            })}
          </tbody>
        </Table>
      </Card.Section>
    </Card>
  );
};

export default ProcessedOrders;
