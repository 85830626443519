import {
  createStyles,
  Paper,
  Stack,
  Group,
  Button,
  Text,
  TextInput,
  Textarea,
  Checkbox,
  Anchor,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconCheck } from '@tabler/icons';
import { motion } from 'framer-motion';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { StandardPop } from '../../Utils/MotionConfigs';
import { initialResqd, ResqdContext } from '../../Utils/ResqdProvider';
import validator from 'validator';
import moment from 'moment';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { Order } from '../../Utils/Classes/Order.class';
import { showNotification } from '@mantine/notifications';
import { calculateTotal } from '../../Utils/Functions';
import ReactMarkdown from 'react-markdown';

const useStyles = createStyles((theme, _params, getRef) => ({
  root: {
    width: 500,
    maxWidth: '100%',
    border: `solid 2px ${theme.colors.dark[0]}`,
    boxShadow: Array.from(Array(8))
      .map((_, i) => `${i + 1}px ${i + 1}px ${theme.colors.dark[0]}`)
      .join(', '),

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      paddingInline: theme.spacing.md,
    },
  },
}));

const Step4 = () => {
  const { classes } = useStyles();
  const { formData, setOrder, settings } = useContext(ResqdContext);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      name: formData.identity.name,
      email: formData.identity.email,
      phone: formData.identity.phone,
      registration: formData.identity.registration,
      other: formData.identity.other,
      terms: false,
    },

    validate: {
      name: (value) =>
        value && value.includes(' ') ? null : 'Ange både för- och efternamn',
      email: (value) =>
        validator.isEmail(value) ? null : 'Ange en giltig e-postadress',
      phone: (value) => {
        try {
          const phoneNumber = parsePhoneNumber(value, 'SE');
          if (phoneNumber && phoneNumber.isValid()) {
            return null;
          }
        } catch (error) {}
        return 'Ange ett giltigt telefonnummer';
      },
      registration: (value) =>
        /^(\w){6}-(\w){4}$/.test(value)
          ? null
          : 'Ange organisationsnummer formatterat XXXXXX-XXXX',
      terms: (value) =>
        value
          ? null
          : 'Du måste godkänna vår behandling av dina personuppgifter för att fortsätta',
    },
  });
  const [deliveryDate, setDeliveryDate] = useState(formData.identity.delivery);

  const [loading, setLoading] = useState(false);
  const submit = () => {
    if (loading) return;
    setLoading(true);
    const { name, email, phone, registration, other } = form.values;

    new Order()
      .create({
        name,
        email,
        phone,
        registration,
        other,
        cost: calculateTotal(formData.articles, settings).total,
        vat: calculateTotal(formData.articles, settings).vat,
        delivery_date: deliveryDate,
        products: formData.articles,
      })
      .then((res) => {
        setOrder(res);
        setLoading(false);
        formData.setArticles(initialResqd.formData.articles);
        formData.setEmployees(initialResqd.formData.employees);
        formData.setIdentity(initialResqd.formData.identity);
        formData.setType(initialResqd.formData.type);
        navigate('/step5');
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        showNotification({
          title: 'Något gick snett',
          message:
            'Någonting gick inte riktigt som det skulle när vi skulle skapa din order. Var vänlig försök igen senare eller kontakta kundtjänst',
          autoClose: 5000,
          color: 'red',
        });
      });
  };

  return (
    <motion.div {...StandardPop()}>
      <Paper withBorder p={40} radius="lg" className={classes.root}>
        <Stack align={'stretch'} spacing="lg">
          <Stack spacing={'xs'}>
            <ReactMarkdown
              children={settings.text.step4_title}
              components={{
                p({ node, className, children, ...props }) {
                  return (
                    <Text size={'xl'} align="center">
                      {children}
                    </Text>
                  );
                },
                strong({ node, className, children, ...props }) {
                  return (
                    <Text component="span" weight={'bold'}>
                      {children}
                    </Text>
                  );
                },
              }}
            />
          </Stack>
          <Stack>
            <TextInput
              label="För- och efternamn"
              placeholder="John Doe"
              required
              autoComplete="name"
              {...form.getInputProps('name')}
            />
            <TextInput
              label="E-postadress"
              placeholder="din@epost.se"
              type="email"
              required
              autoComplete="email"
              {...form.getInputProps('email')}
            />
            <TextInput
              label="Telefonnummer"
              placeholder="070-123 45 67"
              type="tel"
              required
              autoComplete="tel"
              {...form.getInputProps('phone')}
              onChange={(v) => {
                form.setFieldValue(
                  'phone',
                  new AsYouType('SE').input(v.target.value)
                );
              }}
            />
            <TextInput
              label="Organisationsnummer"
              placeholder="556789-0123"
              required
              {...form.getInputProps('registration')}
            />
            <DateTimePicker
              label="Önskad leveranstid"
              placeholder="Ange önskad leveranstid"
              required
              locale="sv"
              valueFormat="YYYY-MM-DD HH:mm"
              minDate={moment().add(2, 'days').toDate()}
              maxDate={moment().add(3, 'months').toDate()}
              value={deliveryDate}
              onChange={(date) =>
                setDeliveryDate(date || initialResqd.formData.identity.delivery)
              }
            />
            <Textarea
              label="Övrigt"
              placeholder="Övriga önskemål eller funderingar"
              {...form.getInputProps('other')}
            />
            <Checkbox
              label={
                <>
                  Jag godkänner RESQD's{' '}
                  <Anchor
                    href="https://www.resqd.se/behandling-av-personuppgifter/"
                    target={'_blank'}
                  >
                    behandling av mina personuppgifter
                  </Anchor>
                </>
              }
              {...form.getInputProps('terms')}
            />
          </Stack>
          <Group position="apart">
            <Link to={'/step3'}>
              <Button size="lg" variant="subtle">
                <IconArrowLeft size={20} />
              </Button>
            </Link>
            <Button
              size="lg"
              rightIcon={<IconCheck size={20} />}
              onClick={() => {
                if (form.validate().hasErrors) {
                  return;
                }
                submit();
              }}
              loading={loading}
            >
              {settings.text.step4_button}
            </Button>
          </Group>
        </Stack>
      </Paper>
    </motion.div>
  );
};

export default Step4;
