import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import {
  Button,
  Container,
  Paper,
  PasswordInput,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useContext, useState } from 'react';
import { ResqdContext } from '../../Utils/ResqdProvider';
import isEmail from 'validator/lib/isEmail';
import { FirebaseError } from 'firebase/app';
import { IconLock, IconMail } from '@tabler/icons';

const AdminWrapper = ({ children }: { children?: React.ReactNode }) => {
  const { user } = useContext(ResqdContext);

  if (!user) {
    // return login form
    return <LoginForm />;
  } else {
    // return children
    return <>{children}</>;
  }
};

export default AdminWrapper;

const LoginForm = () => {
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) => (isEmail(value) ? null : 'Ange en giltig e-post'),
      password: (value) => (value ? null : 'Ange ditt lösenord'),
    },
  });

  const [loading, setLoading] = useState(false);

  const submit = () => {
    if (loading) return;
    const { email, password } = form.values;
    setLoading(true);

    signInWithEmailAndPassword(getAuth(), email, password)
      .then(() => {
        setLoading(false);
      })
      .catch((err: FirebaseError) => {
        switch (err.code) {
          case 'auth/user-not-found':
            form.setFieldError('email', 'Användaren finns inte.');
            break;
          case 'auth/wrong-password':
            form.setFieldError('password', 'Felaktigt lösenord');
            break;
          default:
            form.setFieldError(
              'password',
              'Någonting gick fel. Vänligen försök igen senare.'
            );
        }
        setLoading(false);
      });
  };

  return (
    <Container size={420} my={80}>
      <Title align="center" weight={'bold'} mb={40}>
        Välkommen tillbaka!
      </Title>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput
          label="E-post"
          placeholder="din@epost.se"
          icon={<IconMail size={16} />}
          required
          {...form.getInputProps('email')}
        />
        <PasswordInput
          label="Lösenord"
          placeholder="Ditt lösenord"
          icon={<IconLock size={16} />}
          required
          mt="md"
          {...form.getInputProps('password')}
        />
        <Button
          fullWidth
          mt="xl"
          type="submit"
          loading={loading}
          onClick={() => {
            if (form.validate().hasErrors) {
              return;
            }
            submit();
          }}
        >
          Logga in
        </Button>
      </Paper>
    </Container>
  );
};
