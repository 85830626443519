import { Button, Card, Group, NumberInput, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconSettings } from '@tabler/icons';
import React, { useContext, useEffect, useState } from 'react';
import { ResqdContext } from '../../../Utils/ResqdProvider';
import { updateDoc, doc, getFirestore } from 'firebase/firestore';
import { showNotification } from '@mantine/notifications';

const General = () => {
  const { settings } = useContext(ResqdContext);

  const form = useForm({
    initialValues: {
      freight_cost: settings.freight_cost,
    },
  });

  useEffect(() => {
    form.setValues(settings)
  }, [settings])
  

  const [loading, setLoading] = useState(false);
  const submit = () => {
    if (loading) return;
    setLoading(true);

    const { freight_cost } = form.values;

    updateDoc(doc(getFirestore(), 'platform', 'settings'), {
      freight_cost,
    })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        showNotification({
          title: 'Något gick snett',
          message:
            'Någonting gick inte riktigt som det skulle när dina ändringar sparades. Var vänlig försök igen senare.',
          autoClose: 5000,
          color: 'red',
        });
      });
  };

  return (
    <Card withBorder shadow={'sm'} radius="md">
      <Card.Section withBorder inheritPadding py={'xs'}>
        <Group spacing={'xs'}>
          <IconSettings size={20} />
          <Text weight={500}>Generellt</Text>
        </Group>
      </Card.Section>
      <Stack py={'md'}>
        <NumberInput
          label="Frakt (kr)"
          placeholder="Standardfraktkostnad"
          required
          decimalSeparator=","
          precision={2}
          step={0.05}
          min={0}
          {...form.getInputProps('freight_cost')}
        />
      </Stack>
      <Card.Section withBorder inheritPadding py={'xs'}>
        <Group position="right">
          <Button size="sm" variant="light" onClick={submit} loading={loading}>
            Spara
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
};

export default General;
