import { ResqdProps } from './ResqdProvider';

export const calculateTotal = (
  articles: ResqdProps['formData']['articles'],
  settings: ResqdProps['settings']
): {
  total: number;
  vat: number;
} => {
  let total = 0;
  let vat = 0;
  Object.values(articles).forEach((value) => {
    total += value.cost;
  });

  // Add 12% VAT
  vat += total * 0.12;

  // Add freight costs
  total += settings.freight_cost;

  // Add freight 25% VAT
  vat += settings.freight_cost * 0.25;

  return {
    total,
    vat,
  };
};

export const calculateEmissions = (
  articles: ResqdProps['formData']['articles'],
  format: 'g' | 'kg' | 'ton' = 'g'
) => {
  let result = 0;
  Object.values(articles).forEach((value) => {
    result += value.greenhouse_reduction;
  });

  switch (format) {
    case 'kg':
      return Math.ceil((result / 1000) * 100) / 100;
    case 'ton':
      return Math.ceil((result / 1000000) * 100) / 100;
    default:
      return result;
  }
};
