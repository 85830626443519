import {
  Button,
  Card,
  Group,
  Textarea,
  Stack,
  Text,
  Divider,
  TextInput,
  List,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconBlockquote } from '@tabler/icons';
import { useContext, useEffect, useState } from 'react';
import { ResqdContext } from '../../../Utils/ResqdProvider';
import { updateDoc, doc, getFirestore } from 'firebase/firestore';
import { showNotification } from '@mantine/notifications';

const Titles = () => {
  const { settings } = useContext(ResqdContext);

  const form = useForm({
    initialValues: {
      step1_title: '',
      step1_button: '',
      step2_title: '',
      step2_button: '',
      step3_title: '',
      step3_price_text: '',
      step3_price_description: '',
      step3_greenhouse_text: '',
      step3_greenhouse_description: '',
      step3_button: '',
      step4_title: '',
      step4_button: '',
      step5_title: '',
      step5_other: '',
      step6_title: '',
    },

    validate: {
      step1_title: (value) => (value ? null : 'Detta fält är obligatoriskt'),
      step1_button: (value) => (value ? null : 'Detta fält är obligatoriskt'),
      step2_title: (value) => (value ? null : 'Detta fält är obligatoriskt'),
      step2_button: (value) => (value ? null : 'Detta fält är obligatoriskt'),
      step3_title: (value) => (value ? null : 'Detta fält är obligatoriskt'),
      step3_price_text: (value) =>
        value ? null : 'Detta fält är obligatoriskt',
      step3_greenhouse_text: (value) =>
        value ? null : 'Detta fält är obligatoriskt',
      step3_button: (value) => (value ? null : 'Detta fält är obligatoriskt'),
      step4_title: (value) => (value ? null : 'Detta fält är obligatoriskt'),
      step4_button: (value) => (value ? null : 'Detta fält är obligatoriskt'),
      step5_title: (value) => (value ? null : 'Detta fält är obligatoriskt'),
      step5_other: (value) => (value ? null : 'Detta fält är obligatoriskt'),
      step6_title: (value) => (value ? null : 'Detta fält är obligatoriskt'),
    },
  });

  useEffect(() => {
    form.setValues(settings.text);
  }, [settings]);

  const [loading, setLoading] = useState(false);
  const submit = () => {
    if (loading) return;
    setLoading(true);

    const text = form.values;

    updateDoc(doc(getFirestore(), 'platform', 'settings'), { text: text })
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        showNotification({
          title: 'Något gick snett',
          message:
            'Någonting gick inte riktigt som det skulle när dina ändringar sparades. Var vänlig försök igen senare.',
          autoClose: 5000,
          color: 'red',
        });
      });
  };

  return (
    <Card withBorder shadow={'sm'} radius="md">
      <Card.Section withBorder inheritPadding py={'xs'}>
        <Group spacing={'xs'}>
          <IconBlockquote size={20} />
          <Text weight={500}>Texter</Text>
        </Group>
      </Card.Section>
      <Stack py={'md'} spacing="xl">
        <Text size="xs">
          Här följer lite formatteringsinstruktioner för texterna:
          <List size="xs">
            <List.Item>
              För att göra texten fetstilt, omge i 2st asterixer på varje sida;
              t.ex. "Lorem ipsum **dolor** sit amet", där "dolor" kommer vara
              fetstilt
            </List.Item>
            <List.Item>
              För att göra en ny rad, klicka på "enter" 2 gånger.
            </List.Item>
          </List>
        </Text>
        <Stack spacing={4}>
          <Divider label="Steg 1" />
          <Textarea
            label="Titeltext"
            placeholder="Titeltext"
            required
            {...form.getInputProps('step1_title')}
          />
          <TextInput
            label="Knapptext"
            placeholder="Knapptext"
            required
            {...form.getInputProps('step1_button')}
          />
        </Stack>
        <Stack spacing={4}>
          <Divider label="Steg 2" />
          <Textarea
            label="Titeltext"
            placeholder="Titeltext"
            required
            {...form.getInputProps('step2_title')}
          />
          <TextInput
            label="Knapptext"
            placeholder="Knapptext"
            required
            {...form.getInputProps('step2_button')}
          />
        </Stack>
        <Stack spacing={4}>
          <Divider label="Steg 3" />
          <Textarea
            label="Titeltext"
            placeholder="Titeltext"
            required
            {...form.getInputProps('step3_title')}
          />
          <TextInput
            label="Text under pris"
            placeholder="Du sparar {{discount}}"
            description="Använd följande syntax för att inludera beloppet: {{discount}}. Då kommer {{discount}} ersättas med sparbeloppet."
            required
            {...form.getInputProps('step3_price_text')}
          />
          <TextInput
            label="Extratext under pris"
            placeholder="inkl. moms & frakt"
            {...form.getInputProps('step3_price_description')}
          />
          <TextInput
            label="Text under växthusgaser"
            placeholder="Minskning av CO²-utsläpp"
            description="Sök på '[siffra] superscript' för att hitta den upphöjda versionen av siffran du vill skriva."
            required
            {...form.getInputProps('step3_greenhouse_text')}
          />
          <TextInput
            label="Extratext under växthusgaser"
            placeholder="genom att minska matsvinnet"
            {...form.getInputProps('step3_greenhouse_description')}
          />
          <TextInput
            label="Knapptext"
            placeholder="Knapptext"
            required
            {...form.getInputProps('step3_button')}
          />
        </Stack>
        <Stack spacing={4}>
          <Divider label="Steg 4" />
          <Textarea
            label="Titeltext"
            placeholder="Titeltext"
            required
            {...form.getInputProps('step4_title')}
          />
          <TextInput
            label="Knapptext"
            placeholder="Knapptext"
            required
            {...form.getInputProps('step4_button')}
          />
        </Stack>
        <Stack spacing={4}>
          <Divider label="Steg 5" />
          <TextInput
            label="Titeltext"
            placeholder="Tack!"
            required
            {...form.getInputProps('step5_title')}
          />
          <Textarea
            label="Övrig text"
            placeholder="Titeltext"
            required
            {...form.getInputProps('step5_other')}
          />
        </Stack>
        <Stack spacing={4}>
          <Divider label="Steg 6" />
          <TextInput
            label="Titeltext"
            placeholder="Titeltext"
            required
            {...form.getInputProps('step6_title')}
          />
        </Stack>
      </Stack>
      <Card.Section withBorder inheritPadding py={'xs'}>
        <Group position="right">
          <Button size="sm" variant="light" onClick={submit} loading={loading}>
            Spara
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
};

export default Titles;
