import { Stack } from '@mantine/core';
import React from 'react';
import General from './Settings/General';
import Products from './Settings/Products';
import Titles from './Settings/Titles';

const Settings = () => {
  return (
    <Stack spacing={'xl'} my={'xl'} align="stretch">
      <Products />
      <General />
      <Titles />
    </Stack>
  );
};

export default Settings;
