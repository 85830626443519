import { createStyles, Table, Text } from '@mantine/core';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { calculateEmissions } from '../Utils/Functions';
import { ResqdContext } from '../Utils/ResqdProvider';
import { Timestamp } from 'firebase/firestore';
import { Order } from '../Utils/Classes/Order.class';

const useStyles = createStyles((theme, _params, getRef) => ({
  table_label: {
    fontWeight: 100,
    whiteSpace: 'nowrap'
  },

  table_value: {
    fontWeight: 900,
  },
}));

const OrderSummaryTable = ({ providedOrder }: { providedOrder?: Order }) => {
  const { classes } = useStyles();
  const { order: contextOrder } = useContext(ResqdContext);

  const order = useMemo(() => {
    if (providedOrder) return providedOrder;
    return contextOrder;
  }, [providedOrder, contextOrder]);

  const date = useMemo(() => {
    if (order?.props?.delivery_date instanceof Timestamp) {
      return moment(order.props.delivery_date.toDate()).format('Do MMM YYYY HH:mm');
    }
    return moment().format();
  }, [order?.props?.delivery_date]);

  const productRows = useMemo(() => {
    const products = order?.props?.products;
    if (!products) return <></>;

    return (
      <>
        {Object.keys(products).map((key, index) => {
          const product = products[key];
          return (
            <tr key={index}>
              <td className={classes.table_label}>{key}</td>
              <td className={classes.table_value}>
                {Intl.NumberFormat().format(product.amount)} st{' '}
                <Text
                  component="span"
                  color={'dimmed'}
                  className={classes.table_label}
                >
                  ({Intl.NumberFormat().format(product.cost)} kr)
                </Text>
              </td>
            </tr>
          );
        })}
      </>
    );
  }, [order?.props?.products]);

  return (
    <Table>
      <tbody>
        <tr>
          <td className={classes.table_label}>Namn</td>
          <td className={classes.table_value}>{order?.props?.name}</td>
        </tr>
        <tr>
          <td className={classes.table_label}>E-post</td>
          <td className={classes.table_value}>{order?.props?.email}</td>
        </tr>
        <tr>
          <td className={classes.table_label}>Tel. nr.</td>
          <td className={classes.table_value}>{order?.props?.phone}</td>
        </tr>
        <tr>
          <td className={classes.table_label}>Org. nr.</td>
          <td className={classes.table_value}>{order?.props?.registration}</td>
        </tr>
        <tr>
          <td className={classes.table_label}>Önskad leverans</td>
          <td className={classes.table_value}>{date}</td>
        </tr>
        <tr>
          <td className={classes.table_label}>Övrigt</td>
          <td className={classes.table_value}>{order?.props?.other || 'Ingenting'}</td>
        </tr>
        {productRows}
      </tbody>
      <tfoot>
        <tr>
          <th>Totalpris</th>
          <th>{Intl.NumberFormat().format(order?.props?.cost || 0)} kr</th>
        </tr>
        <tr>
          <th>
            <Text component="span" color="dimmed">
              Varav moms
            </Text>
          </th>
          <th>
            <Text component="span" color="dimmed">
              {Intl.NumberFormat().format(order?.props?.vat || 0)} kr
            </Text>
          </th>
        </tr>
        <tr>
          <th>
            <Text component="span" color="green">
              Minskning av CO²
            </Text>
          </th>
          <th>
            <Text component="span" color="green">
              {Intl.NumberFormat().format(
                calculateEmissions(order?.props?.products || {}, 'kg')
              )}{' '}
              kg
            </Text>
          </th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default OrderSummaryTable;
