import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Booking from './Pages/Booking/Booking';
import Admin from './Pages/Admin/Admin';
import './Utils/Firebase';
import ResqdProvider from './Utils/ResqdProvider';
import 'dayjs/locale/sv';
import moment from 'moment';
import 'moment/locale/sv';
import { ModalsProvider } from '@mantine/modals';

moment.locale('sv');

function App() {
  return (
    <ResqdProvider>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colorScheme: 'dark',
          primaryColor: 'green',
          primaryShade: 5,
          fontFamily: "'Averia Sans Libre', sans-serif",
          headings: { fontFamily: 'obviously, sans-serif' },
        }}
      >
        <Notifications />
        <ModalsProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<Booking />} />
              <Route path="admin" element={<Admin />} />
            </Routes>
          </BrowserRouter>
        </ModalsProvider>
      </MantineProvider>
    </ResqdProvider>
  );
}

export default App;
